import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "RequestContext custom attributes";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "requestcontext-custom-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#requestcontext-custom-attributes",
        "aria-label": "requestcontext custom attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h1">{`RequestContext`}</inlineCode>{` custom attributes`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-attributes-using-typewebclientrequestpreparation"
        }}>{`Setting attributes using type://WebClientRequestPreparation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-attributes-using-typerequestoptions"
        }}>{`Setting attributes using type://RequestOptions`}</a></li>
    </ul>
    <p>{`When you are using multiple decorators, you might want to pass some value to the next decorator.
You can do this by attaching attributes to a `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{`. To attach an attribute,
you need to define an `}<inlineCode parentName="p">{`AttributeKey`}</inlineCode>{` first:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import io.netty.util.AttributeKey;

public final class MyAttributeKeys {
    public static final AttributeKey<Integer> INT_ATTR =
            AttributeKey.valueOf(MyAttributeKeys.class, "INT_ATTR");
    public static final AttributeKey<MyBean> BEAN_ATTR =
            AttributeKey.valueOf(MyAttributeKeys.class, "BEAN_ATTR");
    ...
}
`}</code></pre>
    <p>{`Then, you can access them via `}<a parentName="p" {...{
        "href": "type://RequestContext#attr(AttributeKey):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#attr(io.netty.util.AttributeKey)"
      }}>{`type://RequestContext#attr(AttributeKey)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Setting
ctx.setAttr(INT_ATTR, 42);
MyBean myBean = new MyBean();
ctx.setAttr(BEAN_ATTR, new MyBean());

// Getting
Integer i = ctx.attr(INT_ATTR); // i == 42
MyBean bean = ctx.attr(BEAN_ATTR); // bean == myBean
`}</code></pre>
    <p>{`You can also iterate over all the attributes in a context using `}<a parentName="p" {...{
        "href": "type://RequestContext#attrs():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#attrs()"
      }}>{`type://RequestContext#attrs()`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ctx.attrs().forEachRemaining(e -> {
    System.err.println(e.getKey() + ": " + e.getValue());
});
`}</code></pre>
    <h2 {...{
      "id": "setting-attributes-using-typewebclientrequestpreparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-attributes-using-typewebclientrequestpreparation",
        "aria-label": "setting attributes using typewebclientrequestpreparation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting attributes using `}<a parentName="h2" {...{
        "href": "type://WebClientRequestPreparation:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html"
      }}>{`type://WebClientRequestPreparation`}</a></h2>
    <p>{`You can set attributes when building a request using `}<a parentName="p" {...{
        "href": "type://WebClient#prepare():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html#prepare()"
      }}>{`type://WebClient#prepare()`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;

static final AttributeKey<Integer> USER_ID = AttributeKey.valueOf(MyAttributeKeys.class, "USER_ID");
static final AttributeKey<String> USER_SECRET = AttributeKey.valueOf(MyAttributeKeys.class, "USER_SECRET");

WebClient client = WebClient.of("http://example.com"); 
client.prepare()
      .get("/my-service")
      .attr(USER_ID, userId)
      .attr(USER_SECRET, secret)
      .execute();
`}</code></pre>
    <h2 {...{
      "id": "setting-attributes-using-typerequestoptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-attributes-using-typerequestoptions",
        "aria-label": "setting attributes using typerequestoptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting attributes using `}<a parentName="h2" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a></h2>
    <p>{`You can also create a `}<a parentName="p" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a>{` and pass it
to `}<a parentName="p" {...{
        "href": "type://WebClient#execute(HttpRequest,RequestOptions):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html#execute(com.linecorp.armeria.common.HttpRequest,com.linecorp.armeria.client.RequestOptions)?full"
      }}>{`type://WebClient#execute(HttpRequest,RequestOptions)?full`}</a>{` with an `}<a parentName="p" {...{
        "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
      }}>{`type://HttpRequest`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.RequestOptions;
import com.linecorp.armeria.common.HttpRequest;

HttpRequest req = HttpRequest.of(HttpMethod.GET, "/my-service");
RequestOptions options = RequestOptions.builder()
                                       .attr(USER_ID, userId)
                                       .attr(USER_SECRET, secret)
                                       .build();
client.execute(req, options);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      